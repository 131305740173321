<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <b-card-group deck>
            <b-card
                :header="$t('settings.email.group.common')"
                header-tag="header"
            >
                <b-card-text>
                    <b-row>
                        <b-col cols="12">
                            <zw-input-group v-model="form.sender_name"
                                            name="sender_name"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                        <b-col cols="12">
                            <zw-input-group v-model="form.sender_email"
                                            name="sender_email"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                        <b-col cols="12">
                            <zw-input-group v-model="form.test_to_email"
                                            name="test_to_email"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                        <b-col cols="12">
                            <zw-select-group v-model="form.abo_auto_send_invoice_mailtemplate_id"
                                             :options="getEmailTemplates()"
                                             name="abo_auto_send_invoice_mailtemplate_id"
                                             :label-prefix="labelPrefix"
                                             text-field="name"
                                             value-field="id"
                            ></zw-select-group>
                        </b-col>
                        <b-col cols="12">
                            <zw-select-group v-model="form.abo_invoice_header_id"
                                             :options="getHeaders()"
                                             name="abo_invoice_header_id"
                                             :label-prefix="labelPrefix"
                                             text-field="titel"
                                             value-field="id"
                            ></zw-select-group>
                        </b-col>
                        <b-col cols="12">
                            <zw-select-group v-model="form.abo_invoice_footer_id"
                                             :options="getFooters()"
                                             name="abo_invoice_footer_id"
                                             :label-prefix="labelPrefix"
                                             text-field="titel"
                                             value-field="id"
                            ></zw-select-group>
                        </b-col>
                        <b-col cols="12">
                            <zw-select-group v-model="form.auto_send_template_12h_id"
                                             :options="getEmailTemplates()"
                                             name="auto_send_template_12h_id"
                                             :label-prefix="labelPrefix"
                                             text-field="name"
                                             value-field="id"
                            ></zw-select-group>
                        </b-col>
                        <b-col cols="12">
                            <zw-select-group v-model="form.auto_send_template_30d_id"
                                             :options="getEmailTemplates()"
                                             name="auto_send_template_30d_id"
                                             :label-prefix="labelPrefix"
                                             text-field="name"
                                             value-field="id"
                            ></zw-select-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group :label="$t(labelPrefix+'email_warning_users')">
                                <multiselect
                                    v-model="form.email_warning_users"
                                    :options="getUsersList()"
                                    :multiple="true"
                                    track-by="email"
                                    label="email">
                                </multiselect>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-card>

            <b-card
                :header="$t('settings.email.group.smtp')"
                header-tag="header"
            >
                <b-card-text>
                    <b-row>
                        <b-col cols="12">
                            <zw-select-group v-model="form.mail_transport"
                                             :options="['smtp']"
                                             name="mail_transport"
                                             :label-prefix="labelPrefix"
                            ></zw-select-group>
                        </b-col>
                        <b-col cols="12">
                            <zw-input-group v-model="form.smtp_host"
                                            name="smtp_host"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                        <b-col cols="12">
                            <zw-select-group v-model="form.smtp_secure_connection"
                                             :options="['ssl','tls']"
                                             name="smtp_secure_connection"
                                             :label-prefix="labelPrefix"
                            ></zw-select-group>
                        </b-col>
                        <b-col cols="12">
                            <zw-input-group v-model="form.smtp_port"
                                            name="smtp_port"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                        <b-col cols="12">
                            <zw-input-group v-model="form.smtp_username"
                                            name="smtp_username"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                        <b-col cols="12">
                            <zw-input-group v-model="form.smtp_password"
                                            name="smtp_password"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group :label="$t('users.label.test_smtp')">
                                <b-button @click="checkSmtp()" variant="danger">
                                    {{ $t('common.button.test') }}
                                </b-button>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-card>
        </b-card-group>

        <b-row>
            <b-col sm="12" class="text-xs-center mt-2">
                <b-row align-h="end">
                    <b-col sm="6" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </b-col>

        </b-row>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'SettingsEmail',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            form: {},
            labelPrefix: 'settings.email.label.',
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getEmailTemplates']),
        ...mapGetters('Settings', ['getEmail']),
        ...mapGetters('Templates', ['getFooters', 'getHeaders']),
        ...mapGetters('Users', ['getUsersList']),

        shown() {
            this.loading = true
            const settingsEmail = this.$store.dispatch('Settings/fetchEmail');
            this.$store.dispatch('Templates/fetchTemplates', '');
            this.$store.dispatch('Users/fetchUsersList', '');

            Promise.all([settingsEmail]).then(() => {
                this.form = this.getEmail()

                this.loading = false
            });
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.$store.dispatch('Settings/save', this.form).then((response) => {
                        this.afterSave(response)
                    }).catch(errors => {
                        this.$refs['observer'].setErrors(errors)
                        this.showValidationError()
                    })
                } else {
                    this.showValidationError()
                }
            })
        },
        afterSave(response) {
            if (response.status == 200 || response.status == 201) {
                this.$root.showMessage(
                    this.$t('common.form.title.success'),
                    this.$t('common.toasts.success_updated'),
                    'success'
                )

                this.shown()
            }
            this.$root.afterSettingsUpdate()
        },
        checkSmtp() {
            this.loading = true;
            this.$store.dispatch('Users/checkSmtp', this.form)
                .then((response) => {
                    if (response.data.result.status) {
                        this.$root.showMessage(
                            this.$t('common.form.title.success'),
                            'OK',
                            'success'
                        )
                    } else {
                        this.$root.showMessage(
                            this.$t('common.form.title.error'),
                            response.data.result.message,
                            'dangers'
                        )
                    }
                })
                .finally(() => {
                    this.loading = false;
                })
        }
    },
    mounted() {
        this.shown()
    }
}
</script>